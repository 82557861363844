import { OnInit, AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Account } from '@wtax/data-angular';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import * as fromProfile from '@core/store/reducers';
import * as fromAccounts from './reducers';
import * as AccountSelectionActions from './actions/account-selection.actions';

@Component({
  selector: 'app-account-dropdown',
  templateUrl: './account-dropdown.component.html',
  styleUrls: ['./account-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountDropdownComponent implements OnInit, AfterViewInit {
  public readonly userRole$ = this.store$.pipe(select(fromProfile.selectUserRole));
  public accounts$: Observable<Account[]> = this.store$.pipe(select(fromAccounts.selectAccountSelection));

  @Input() public label: string;
  @Input() public countryCode: string;

  public selectedAccount: any;

  constructor(private readonly store$: Store<fromAccounts.AppState>) {}

  public ngOnInit(): void {
    this.getAccounts();

    this.accounts$
      .pipe(
        filter((accounts) => accounts && accounts.length > 0),
        take(1)
      )
      .subscribe((accounts: Account[]) => {
        if (!this.selectedAccount) {
          const primaryAccount = accounts.find((account) => account.primary);
          this.selectedAccount = primaryAccount ? primaryAccount.id : accounts[0]?.id;
          this.onSelect();
        }
      });
  }

  public ngAfterViewInit(): void {}

  @Output() public accountSelectedEvent = new EventEmitter<string>();

  public onSelect() {
    this.accountSelectedEvent.emit(this.selectedAccount);
  }

  public getAccounts(): void {
    this.userRole$.subscribe((role) => {
      if (role) {
        this.store$.dispatch(AccountSelectionActions.loadAccounts());
      }
    });
  }
}
